<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Starter page",
            items: [
                {
                    text: "Utility",
                    href: "/",
                },
                {
                    text: "Starter page",
                    active: true,
                },
            ],
            loading: false,
            appid: "",
            userinfo: [],
            free: {},
            chargeactive: [],
            micoappsaletab: "charge"
        };
    },
    mounted() {
        this.userinfo = JSON.parse(localStorage.getItem("user"));
        this.appid = this.$route.params.appid;
        this.title = "营销设置";
        this.items = [
            {
                text: this.$t("menuitems.micoapp.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.icp.micoapp.list"),
                href: "/micoapp",
            },
            {
                text: this.$t("menuitems.icp.micoapp.info"),
                href: `/micoapp/${this.appid}`,
            },
            {
                text: "营销设置",
                active: true,
            },
        ];
        this.getmicoappmenu();
    },
    methods: {
        getmicoappmenu() {
            var that = this;
            that.loading = true;
            that.$axios
                .post(
                    "https://api.aicoiot.com/micoappapi.php",
                    {
                        action: "micoapp.setting",
                        appid: that.$route.params.appid,
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    that.free = JSON.parse(response.data.data.free);
                    that.chargeactive = JSON.parse(response.data.data.chargeactive);
                })
                .catch(function (error) {
                    that.loading = false;
                    that.$notify.error({
                        title: '请求错误',
                        message: error
                    });
                });
        },
        save_micoappsetting(type) {
            var that = this;
            let postdata = that[type];
            that.loading = true;
            if (postdata) {
                that.$axios
                    .post(
                        "https://api.aicoiot.com/micoappapi.php",
                        {
                            action: "micoapp.savesetting",
                            appid: that.$route.params.appid,
                            type: type,
                            datas: postdata
                        },
                        {
                            headers: { Openid: that.userinfo.openid },
                        }
                    )
                    .then(function (response) {
                        that.loading = false;
                        if (response.data.status == 200) {
                            that.$message.success({
                                message: "保存成功"
                            });
                            that.getmicoappmenu();
                        } else {
                            that.$message.error({
                                message: response.data.message
                            });
                        }
                    })
                    .catch(function (error) {
                        that.loading = false;
                        that.$notify.error({
                            title: '请求错误',
                            message: error
                        });
                    });

            } else {
                that.$message.error({
                    message: "提交数据不存在"
                });
            }
        },
        addrow_charge_sale(){
            this.chargeactive.push({"plan_id":this.micoappsettings.chargeactive.length+1,"money":0,"gift_money":0})
        },
        delete_micoappsetting: function(index, rows){
            rows.splice(index, 1);
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-loading="loading">
                        <el-tabs v-model="micoappsaletab">
                            <el-tab-pane label="充值活动" name="charge">
                                <div style="text-align: center;">
                                    <el-button @click="save_micoappsetting('chargeactive')"
                                        type="primary">保存</el-button>
                                    <el-button @click="addrow_charge_sale" type="success">添加</el-button>
                                </div>
                                <el-table :data="chargeactive" stripe style="width: 100%">
                                    <el-table-column prop="plan_id" label="序号" width="80">
                                    </el-table-column>
                                    <el-table-column label="充值金额">
                                        <template slot-scope="scope">
                                            <el-input type="number" v-model="scope.row.money"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="赠送金额">
                                        <template slot-scope="scope">
                                            <el-input type="number" v-model="scope.row.gift_money"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed="right" label="操作">
                                        <template slot-scope="scope">

                                            <el-button
                                                @click.native.prevent="delete_micoappsetting(scope.$index, micoappsettings.chargeactive)"
                                                type="danger" size="small">
                                                移除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-tab-pane>
                            <el-tab-pane label="订单活动" name="free">
                                <el-form ref="form" :model="free" label-width="80px">
                                    <el-form-item label="免单开关">
                                        <el-switch v-model="free.enable" active-text="开启"
                                            inactive-text="关闭" :active-value="1" :inactive-value="0">
                                        </el-switch>
                                    </el-form-item>
                                    <el-form-item label="免单要求">
                                        <el-select v-model="free.type" placeholder="请选择">
                                            <el-option label="以手机号为基准" value="phone" disabled></el-option>
                                            <el-option label="以微信号为基准" value="wechat" selected></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="免单次数">
                                        <el-input type="number" v-model="free.times"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button @click="save_micoappsetting('free')" type="primary"
                                            v-if="free.type && free.times">保存</el-button>
                                        <el-button @click="save_micoappsetting('free')" type="primary" v-else
                                            disabled>保存</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>